import { combineReducers } from '@reduxjs/toolkit';

import {
  TRANSLATIONS_SLICE_NAME,
  reducer as translationsReducer,
  actions as translationsActions,
} from '@common/store/translations-slice';
import {
  BASE_PROPS_SLICE_NAME,
  reducer as basePropsReducer,
  actions as basePropsActions,
} from '@common/store/base-props-slice';
import {
  MEMBER_COMMENTS_SLICE_NAME,
  reducer as memberReducer,
  actions as memberActions,
} from '@common/store/member-slice/member-comments-slice';
import {
  ERRORS_SLICE_NAME,
  reducer as errorsReducer,
} from '@common/store/errors-slice';

export const rootReducer = combineReducers({
  [BASE_PROPS_SLICE_NAME]: basePropsReducer,
  [MEMBER_COMMENTS_SLICE_NAME]: memberReducer,
  [TRANSLATIONS_SLICE_NAME]: translationsReducer,
  [ERRORS_SLICE_NAME]: errorsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const rootActions = {
  ...basePropsActions,
  ...memberActions,
  ...translationsActions,
};
