import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { initServices } from '@common/services';
import { createBiLogger } from '@common/providers';
import { RootState, initStore, initActions } from './store';

import {
  createMembersAreaWidgetPluginService,
  createWidgetPluginExports,
  routerDataServiceFactory,
} from '@wix/members-area-widget-plugin-lib/viewer';
import { Status } from '@common/store/member-slice';
import { createControllerV1 } from './members-area-v1-controller';

export interface ControllerProps {
  state: RootState;
  actions: ReturnType<typeof initActions>;
}

const createController: CreateControllerFn = async ({ flowAPI }) => {
  const isSSR = flowAPI.environment.isSSR;

  const { setProps, wixCodeApi } = flowAPI.controllerConfig;

  const widgetPluginService = createMembersAreaWidgetPluginService();

  const initialise = async () => {
    const fedopsLogger = flowAPI.fedops;
    const errorLogger = (error: Error) => {
      // @TODO add state update on error
      flowAPI.reportError(error);
    };
    const biLogger = createBiLogger(flowAPI.bi!, {
      defaultConfig: {
        src: 69,
        endpoint: 'forums-uou',
      },
      defaultParams: {
        origin: 'member_comments_page',
      },
    });

    const servicesConfig = initServices(flowAPI);
    const store = initStore(servicesConfig, errorLogger);
    const actions = initActions(
      wixCodeApi,
      store,
      servicesConfig,
      errorLogger,
      biLogger,
      fedopsLogger,
    );

    if (!isSSR) {
      actions.logBiEvent({ evid: 118, type: 'member_comments_page' });
    }

    const routerDataService = routerDataServiceFactory(
      widgetPluginService,
      wixCodeApi.user,
      wixCodeApi.window,
    );

    actions.initAppBase({
      // @TODO add language validation from available forum languages
      language: wixCodeApi.site.language || 'en',
    });

    widgetPluginService.onMembersAreaEvent(async (event) => {
      switch (event.type) {
        case 'MEMBERS_CHANGED':
          return actions.resetMemberComments();
        case 'TAB_CHANGED':
          const memberComments = store.getState().memberComments;
          const newViewedUserId = (await routerDataService.getRouteData())
            ?.viewedMemberIdentifier?.id;
          if (
            memberComments.status !== Status.INITIAL ||
            !newViewedUserId ||
            event.payload !== '14f51b00-3ada-272e-b6db-34d5da9dd623'
          ) {
            return;
          }
          return actions.initAppData({ viewedUserId: newViewedUserId });
      }
    });

    setProps({
      actions,
      state: store.getState(),
      fitToContentHeight: true,
    });

    store.subscribe(() => {
      setProps({
        actions,
        state: store.getState(),
      });
    });
  };

  return {
    async pageReady() {
      if (!widgetPluginService.getIsWidgetPlugin()) {
        return createControllerV1(flowAPI);
      }
      const isRendered = widgetPluginService.getIsRendered();

      if (!isRendered) {
        setProps({ isRendered });
        return Promise.resolve();
      }

      await initialise();
    },
    exports() {
      return createWidgetPluginExports(widgetPluginService, initialise);
    },
  };
};

export default createController;
